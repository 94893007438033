import React from 'react';

const LinkedInIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="35" cy="35" r="34.5" stroke="#23FBEC" />
    <path
      d="M36.0286 29.4086C37.7578 27.6417 40.0093 26.4857 42.6286 26.4857C45.3792 26.4857 48.0173 27.5784 49.9623 29.5234C51.9073 31.4685 53 34.1065 53 36.8571V51H49.2286V36.8571C49.2286 35.1067 48.5332 33.428 47.2955 32.1902C46.0577 30.9525 44.379 30.2571 42.6286 30.2571C40.8781 30.2571 39.1994 30.9525 37.9617 32.1902C36.7239 33.428 36.0286 35.1067 36.0286 36.8571V51H32.2571V27.4286H36.0286V29.4086ZM22.8286 23.6571C22.0784 23.6571 21.3589 23.3591 20.8285 22.8287C20.298 22.2982 20 21.5788 20 20.8286C20 20.0784 20.298 19.3589 20.8285 18.8285C21.3589 18.298 22.0784 18 22.8286 18C23.5788 18 24.2982 18.298 24.8287 18.8285C25.3591 19.3589 25.6571 20.0784 25.6571 20.8286C25.6571 21.5788 25.3591 22.2982 24.8287 22.8287C24.2982 23.3591 23.5788 23.6571 22.8286 23.6571ZM20.9429 27.4286H24.7143V51H20.9429V27.4286Z"
      fill="#23FBEC"
    />
  </svg>
);

export default LinkedInIcon;
