import React from 'react';

const BehanceIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="35" cy="35" r="34.5" stroke="#23FBEC" />
    <path
      d="M41 24.5455H53.5V27.0909H41V24.5455ZM47.2695 29.6364C49.9909 29.6364 52.1263 30.6042 53.6758 32.5398C55.2253 34.4754 56 36.9015 56 39.8182H41C41 42.072 41.5273 43.9081 42.582 45.3267C43.6367 46.7453 45.1927 47.4545 47.25 47.4545C48.8906 47.4545 50.2057 46.9905 51.1953 46.0625C52.1849 45.1345 52.862 43.9015 53.2266 42.3636H55.7656C55.349 44.6439 54.4049 46.4867 52.9336 47.892C51.4622 49.2973 49.5677 50 47.25 50C44.5417 50 42.4062 49.0322 40.8438 47.0966C39.2812 45.161 38.5 42.7348 38.5 39.8182C38.5 36.9015 39.2812 34.4754 40.8438 32.5398C42.4062 30.6042 44.5482 29.6364 47.2695 29.6364ZM53.2266 37.2727C52.888 35.7083 52.2174 34.4687 51.2148 33.554C50.2122 32.6392 48.8971 32.1818 47.2695 32.1818C45.6419 32.1818 44.3268 32.6392 43.3242 33.554C42.3216 34.4687 41.638 35.7083 41.2734 37.2727H53.2266ZM36 42.3636C36 44.4583 35.1862 46.2547 33.5586 47.7528C31.931 49.2509 29.9583 50 27.6406 50H16V22H26.1953C28.2005 22 29.9193 22.7424 31.3516 24.2273C32.7839 25.7121 33.5 27.5152 33.5 29.6364C33.5 31.7841 32.8359 33.6402 31.5078 35.2045C32.7839 35.7614 33.8516 36.696 34.7109 38.0085C35.5703 39.321 36 40.7727 36 42.3636ZM26.3125 24.5455H18.5V34.7273H26.3125C27.6146 34.7273 28.7214 34.2301 29.6328 33.2358C30.5443 32.2415 31 31.0417 31 29.6364C31 28.2311 30.5443 27.0312 29.6328 26.0369C28.7214 25.0426 27.6146 24.5455 26.3125 24.5455ZM27.875 37.2727H18.5V47.4545H27.875C29.4115 47.4545 30.7331 46.9574 31.8398 45.9631C32.9466 44.9687 33.5 43.7689 33.5 42.3636C33.5 40.9583 32.9466 39.7585 31.8398 38.7642C30.7331 37.7699 29.4115 37.2727 27.875 37.2727Z"
      fill="#23FBEC"
    />
  </svg>
);

export default BehanceIcon;
