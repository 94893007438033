import React from 'react';

const CodePenIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="35" cy="35" r="34.5" stroke="#23FBEC" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M34.3427 18.2107C34.5346 18.074 34.7644 18.0006 35 18.0006C35.2356 18.0006 35.4654 18.074 35.6573 18.2107L51.524 29.544C51.6711 29.6488 51.791 29.7871 51.8738 29.9476C51.9566 30.1081 51.9999 30.286 52 30.4666V39.5332C51.9999 39.7138 51.9566 39.8917 51.8738 40.0522C51.791 40.2127 51.6711 40.351 51.524 40.4558L35.6573 51.7891C35.4654 51.9258 35.2356 51.9992 35 51.9992C34.7644 51.9992 34.5346 51.9258 34.3427 51.7891L18.476 40.4558C18.3289 40.351 18.209 40.2127 18.1262 40.0522C18.0434 39.8917 18.0001 39.7138 18 39.5332V30.4666C18.0001 30.286 18.0434 30.1081 18.1262 29.9476C18.209 29.7871 18.3289 29.6488 18.476 29.544L34.3427 18.2107V18.2107ZM20.2667 32.6698L23.5307 34.9999L20.2667 37.33V32.6698ZM21.0827 39.5332L33.8667 48.6634V42.3847L25.48 36.3894L21.0827 39.5332ZM27.4293 34.9999L35 40.4082L42.5707 34.9999L35 29.5916L27.4293 34.9999ZM36.1333 27.6174L44.52 33.6082L48.9173 30.4666L36.1333 21.3364V27.6151V27.6174ZM33.8667 21.3364V27.6151L25.48 33.6104L21.0827 30.4666L33.8667 21.3364ZM49.7333 32.6698L46.4693 34.9999L49.7333 37.33V32.6698V32.6698ZM48.9173 39.5332L44.52 36.3916L36.1333 42.3824V48.6634L48.9173 39.5332Z"
      fill="#23FBEC"
    />
  </svg>
);

export default CodePenIcon;
